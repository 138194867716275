import React from 'react';
import background from '../../assets/imgs/hadithbanner.jpg'





function HadithSection() {

    return (

        <div className='HadeesContainer'>
            <img src={background} />
            <div className='HadeesInnerContainer'>
                <p>The Prophet (peace be upon him) said: “Whoever builds a mosque for Allah, Allah will build for him likewise in Paradise.
                    The Prophet (peace be upon him) said: “Whoever builds a mosque for Allah, Allah will build for him likewise in Paradise.</p>
                <h3>[Sahih al-Bukhari and Sahih Muslim]</h3>
            </div>

        </div>
    );
}


export default HadithSection;


