import React from 'react';

import { CTATwo } from "master-react-lib";
import LoveIcon from '../../assets/imgs/loveIcon.svg';


function DonationCardTwoComp() {

    return (
        <a className='CTACARD' href='https://give.deenfund.com/bicny' target='_blank'>
            <CTATwo
                icon={LoveIcon}
                IconbackgroundColor="#28CC8E"
                Title="Support us with your generous donation"
                ButtonLebel="DONATE NOW"
                ButtonColor="#28CC8E"
                link="https://give.deenfund.com/bicny"
                target="_blank"
            />
        </a>
    );
}


export default DonationCardTwoComp;