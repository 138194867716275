import React, { useState, useEffect } from 'react';
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import Grid from '@material-ui/core/Grid';
import PageTitle from '../PageTitle/PageTitle'
import HadithSection from '../HadithSection/HadithSection';
import DonationCardTwoComp from "../SidebarWidgets/DonationCardTwo"
import DonateBanner from '../DonateBanner/DonateBanner'
import Services from '../Services/Services'
import LatestReadAndViews from '../SidebarWidgets/LatestReadAndViews'
import PromotionalSection from '../PromotionalSection/PromotionalSection'
import EventLocation from '../EventLocation/EventLocation'
import '../../assets/css/style.css'
import { Section, SideBarBody, CardEight, UserAvatarFour, } from "master-react-lib";
import img from '../../assets/imgs/img.png';
import FacebookFeeds from "../FacebookFeeds/FacebookFeeds";
import { getSingleEvent } from '../../functions';
import facebookIcon from '../../assets/imgs/facebook.png'
import twitter from '../../assets/imgs/twitter.png'
import AddIcon from '../../assets/imgs/AddIcon.png'


const orgId = process.env.REACT_APP_ORGANIZATION_ID;



function EventsDetailsPage() {



    const [eventData, setEventData] = useState({
        description: [
            {
                'children': [{
                    'text': ''
                }

                ]
            }
        ]
    });

    const eventCardOneData = [

        {
            img: eventData?.imageDetails?.imageUrl,
            title: eventData?.title,
            // date: eventData?.createdAt,
            date:"JULY 9, 2022, 7:30 AM - JULY 9, 2022, 8:30 AM EST",
            // location: eventData?.location?.address?.line1 + eventData?.location?.address?.line2 + eventData?.location?.address?.city + eventData?.location?.address?.state,
            location:<span>Prospect Park - Parade Ground No.8 <br/> Entrance - Caton Ave and Argyle Road</span>,
            amount: eventData?.entry?.feeRequired,
            food: eventData?.refreshment?.providedList.join(', '),
            join: eventData?.entry?.entryType,

        }

    ];


    useEffect(async () => {

        const queryParams = new URLSearchParams(window.location.search)
        const term = queryParams.get("q");

        let news = await getSingleEvent(orgId, term);

        if (news && news.data.body && (news.data.body.data)) {
            news.data.body.data.description = JSON.parse(news.data.body.data.description);
            setEventData(news.data.body.data);
            if (news.data.body.data && news.data.body.data.speakers) {
                let speaker = news.data.body.data.speakers[0];
                let obj = {
                    img: speaker?.imageMetaData?.imageUrl,
                    Title: speaker?.name.firstName + ' ' + speaker.name?.lastName,
                    Designation: '',
                    path: "",
                    link: "https://www.google.com/",
                };

                console.log(obj);

                setGuard(obj);
            }
        }
    }, []);


    const [speakerData, setGuard] = useState({
        img: 'https://d2imgltiwla1iy.cloudfront.net/270ba191d372c64c70c1ac9f47365a4d-Darul Jannah/164441728351879b2aafd-d3fc-492b-9993-c057c00de712.jpeg',

    });


    return (

        <div>
            <Header />


            <div className="MainWrapper">

                <PageTitle
                    Title="UPCOMING BIC EVENTS"
                />
                <HadithSection />


                <Section
                    background="#F4F9FC"
                    //ContainerWidth=""
                    Spacing="40px"

                    Container={
                        <div>

                            <Grid container spacing={3}>

                                <Grid item md={8} sm={8} xs={12}>
                                    <div className='EventDetailsContainer'>
                                        <CardEight
                                            eventCardOneData={eventCardOneData}
                                            col="12"
                                            buttonLabel="View event Details"
                                            ImgWidth="255px"
                                            groupStatus={false}
                                            viewdetailStatus={false}
                                            DateRangeStatus={true}
                                            LocationStatus={true}
                                            MoneyStatus={true}
                                            FoodStatus={true}
                                            JoinStatus={true}

                                            // SocialIcon={
                                            //     <div>
                                            //         <a href="#"><img src={AddIcon} /></a>
                                            //         <a href="#"><img src={facebookIcon} /></a>
                                            //         <a href="#"><img src={twitter} /></a>
                                            //     </div>
                                            // }
                                        />

                                    </div>


                                    <div className='EventdetailsInfoContainer paraSection'>

                                        <h2>EVENT DESCRIPTION</h2>
                                        <p>{eventData?.description[0]?.children[0]?.text}</p>
                                    </div>

                                    <div className='EventdetailsInfoContainer paraSection'>

                                        <h2>SPEAKERS</h2>
                                        <UserAvatarFour
                                            img={speakerData?.img}
                                            Title={speakerData?.Title}
                                            Designation={speakerData?.Designation}
                                        />
                                    </div>

                                    <div className='EventdetailsInfoContainer paraSection'>

                                        <h2>EVENT LOCATION</h2>
                                        <EventLocation
                                            location={eventCardOneData[0]?.location} />
                                    </div>






                                    <div style={{ display: "flex", justifyContent: "center", marginTop: "40px", }}>
                                        <DonateBanner />
                                    </div>

                                </Grid>

                                <Grid item md={4} sm={4} xs={12}>

                                    <Grid container spacing={3}>

                                        <Grid item md={12} sm={12} xs={12}>
                                            <DonationCardTwoComp />

                                        </Grid>

                                        <Grid item md={12} sm={12} xs={12}>
                                            <FacebookFeeds />
                                        </Grid>

                                        <Grid item md={12} sm={12} xs={12}>
                                            <SideBarBody
                                                background="#fff"
                                                border="#E4E8EC 1px solid"
                                                SideBarBody={
                                                    <LatestReadAndViews />
                                                }
                                            />
                                        </Grid>
                                    </Grid>


                                </Grid>
                            </Grid>

                        </div>
                    }
                />



            </div>

            <Services />
            <DonateBanner />
            <div className='PromotionalSectionBorder'>
                <PromotionalSection />
            </div>

            <Footer />
        </div>
    );
}


export default EventsDetailsPage;