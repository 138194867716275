import React from 'react';
import { CTAOne   } from "master-react-lib";


function DonateCardOneComp() {

    return (

        <a className='CTACARD' href='https://give.deenfund.com/bicny' target='_blank'>
        <CTAOne 
        backgroundColor="#FF6200"
        Title="Build a house of Allah, and Allah will build one for you in Jannah"
        SubTitle="Al-Tirmidhi"
        ButtonLebel="DONATE NOW"
        link="https://give.deenfund.com/bicny"
        target="blank"
        minHeight="242px"

    />
    </a>
    );
}


export default DonateCardOneComp;