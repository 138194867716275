import React, { useEffect } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";

import "../../assets/css/style.css";
import { Container } from "@mui/material";

function Donate() {
  useEffect(() => {
    window.location.href = "https://give.deenfund.com/bicny";
  }, []);
  return (
    <div>
      <Header />
      <div className="MainWrapper">
        <Container
          style={{
            minHeight: "40vh",
            display: "flex",
            gap: "12px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div class="spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <p>Redirecting to donation form</p>
        </Container>
      </div>
      <Footer />
    </div>
  );
}

export default Donate;
