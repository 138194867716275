import React from 'react';
import background from '../../assets/imgs/BICPattern.svg'





function HadithSectionSidebar() {

    return (

        <div className='HadithSectionSidebarContainer'>
            <img className='HadithSectionSidebarPattern' src={background} />
            <div className='HadithSectionSidebarInnerContainer'>
            <h3>UNITY & BROTHERHOODS</h3>
                <p>“They say; Our Lord, forgive us and our brothers who preceded us in faith and put not in out hearts any resentment toward those who have believed. 
                    Out Lord, You are kind and Merciful.”</p>
                
            </div>

        </div>
    );
}


export default HadithSectionSidebar;


