import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Home from "./components/Pages/Home";
import AboutUs from "./components/Pages/AboutUs";
import ReadsViewsPage from "./components/Pages/ReadsViewsPage";
import ReadsDetailsPage from "./components/Pages/ReadsDetailsPage";
import VideosPage from "./components/Pages/VideosPage";
import EventsPage from "./components/Pages/EventsPage";
import EventsDetailsPage from "./components/Pages/EventsDetailsPage";
import FaqPage from "./components/Pages/FaqPage";
import VideosSection from "./components/VideosSection/VideosSection";
import Donate from "./components/Pages/Donate";

function App() {
  return (
    <Router>
      <Route exact path={"/"} component={() => <Home />} />
      <Route exact path={"/about-us"} component={() => <AboutUs />} />
      <Route exact path={"/reads-views"} component={() => <ReadsViewsPage />} />
      <Route
        exact
        path={"/reads-details"}
        component={() => <ReadsDetailsPage />}
      />
      <Route exact path={"/videos"} component={() => <VideosPage />} />
      <Route exact path={"/events"} component={() => <EventsPage />} />
      <Route
        exact
        path={"/events-details"}
        component={() => <EventsDetailsPage />}
      />
      <Route exact path={"/faq"} component={() => <FaqPage />} />
      <Route exact path={"/videosection"} component={() => <VideosSection />} />
      <Route exact path={"/donate"} component={() => <Donate />} />
    </Router>
  );
}

export default App;
