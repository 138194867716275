import React from 'react';
import { PageTitleOne } from "master-react-lib";
import backgroundPattern from "../../assets/imgs/./BICPattern.svg";


function PageTitle(props) {

    return (

        <PageTitleOne
            Title={props.Title}
            SubTitle={props.Subtitle}
            color="#fff"
            backgroundcolor="#0053A5"
            backgroundPattern={backgroundPattern}
        />
    );
}


export default PageTitle;