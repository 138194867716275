import React, { useState, useEffect } from 'react';
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import Grid from '@material-ui/core/Grid';
import PageTitle from '../PageTitle/PageTitle'
import HadithSection from '../HadithSection/HadithSection';
import DonationCardTwoComp from "../SidebarWidgets/DonationCardTwo"
import DonateBanner from '../DonateBanner/DonateBanner'
import Services from '../Services/Services'
import LatestReadAndViews from '../SidebarWidgets/LatestReadAndViews'
import PromotionalSection from '../PromotionalSection/PromotionalSection'
import '../../assets/css/style.css'
import { Section, SideBarBody, CardEight, NoAvailableEvents } from "master-react-lib";
import img from '../../assets/imgs/img.png';
import SetionTitle from "../SetionTitle/SetionTitle"
import { getEvents } from '../../functions';
import moment from 'moment';
import FacebookFeeds from "../FacebookFeeds/FacebookFeeds"; 
const orgId = process.env.REACT_APP_ORGANIZATION_ID;
// import moment from 'moment';




function EventsPage() {

    useEffect(async () => {

        const items = await getEvents(orgId, 'deenweb');
        let tempData = [];

        if (items && items.data.body.data && Array.isArray(items.data.body.data)) {
            items.data.body.data.filter( x => x.status === "Public" ).map(element => {
                let object = {
                    img: element.event.imageDetails.imageUrl,
                    title: element.event.title,
                    date:"JULY 9, 2022 | 7:30 AM",
                    // date: `${moment( element.event.eventStart?.startAt ).format('MMMM Do YYYY')} - ${moment( element.event?.endAt ).format('MMMM Do YYYY')}`,
                    time: element.event.eventStart.startTime,
                    location:<span>Prospect Park - Parade Ground No.8 <br/> Entrance - Caton Ave and Argyle Road</span>,
                    // location: `${element.event?.location.address.line1} ${element.event?.location.address.line2} ${element.event?.location.address.city} ${element.event?.location.address.state}`,
                    link: '/events-details?q=' + element?.event?._id,
                    speakers: element.event?.speakers.map( speaker => {
                        return {
                            name: `${speaker.name.firstName} ${speaker.name.lastName}`,
                            image: speaker.imageMetaData.imageUrl
                        };
                    })
                };
                tempData.push(object);
            });

            setEventData(tempData);
        }

    }, []);



    const [eventCardOneData, setEventData] = useState([]);


    return (

        <div>
            <Header />


            <div className="MainWrapper">

                <PageTitle
                    Title="UPCOMING BIC EVENTS"
                />
                <HadithSection />


                <Section
                    background="#F4F9FC"
                    //ContainerWidth=""
                    Spacing="40px"

                    Container={
                        <div>

                            <SetionTitle
                                TitleLight="UPCOMING"
                                TitleBold="BIC EVENTS"
                                ButtonStatus={false}
                            />

                            <Grid container spacing={3}>

                                <Grid item md={8} sm={8} xs={12}>

                                    {eventCardOneData.length && (
                                        <CardEight
                                            eventCardOneData={eventCardOneData}
                                            col="12"
                                            buttonLabel="View event Details"
                                            ImgWidth="204px"
                                            groupStatus={true}
                                            viewdetailStatus={true}
                                            DateRangeStatus={true}
                                            LocationStatus={true}
                                            MoneyStatus={false}
                                            FoodStatus={false}
                                            JoinStatus={false}

                                        />
                                    )}

                                    {!eventCardOneData.length && (
                                        <NoAvailableEvents
                                            Title="Oops! No available events"
                                            SubTitle="Any available events will appear here."
                                            Description="In the meantime, our secured donation form is superbly ready as we continuously need your generous donations. Please click on the button below to support us."


                                            background="#fff"
                                            //// Button Style ////
                                            buttonLebel="I WANT TO DONATE"
                                            buttonLebelLink="https://give.deenfund.com/bicny"
                                            target="blank"
                                            path="https://give.deenfund.com/bicny"
                                            arrow={false}
                                            arrowColor="#fff"
                                            buttoncolor="#0053A5"
                                            width="198px"
                                            height="50px"
                                            border="#ccc 0px solid"
                                            borderRadius="4px"
                                            colorlabel="#fff"
                                            fontWeight="600"
                                            textAlign="center"
                                        />
                                    )}





                                    <div style={{ display: "flex", justifyContent: "center", marginTop: "40px", }}>
                                        <DonateBanner />
                                    </div>

                                </Grid>

                                <Grid item md={4} sm={4} xs={12}>

                                    <Grid container spacing={3}>

                                        <Grid item md={12} sm={12} xs={12}>
                                            <DonationCardTwoComp /> 
                                        </Grid>

                                        <Grid item md={12} sm={12} xs={12}>
                                         <FacebookFeeds />
                                        </Grid>

                                        <Grid item md={12} sm={12} xs={12}>
                                            <SideBarBody
                                                background="#fff"
                                                border="#E4E8EC 1px solid"
                                                SideBarBody={
                                                    <LatestReadAndViews />
                                                }
                                            />
                                        </Grid>
                                    </Grid>


                                </Grid>
                            </Grid>

                        </div>
                    }
                />



            </div>

            <Services />
            <DonateBanner />
            <div className='PromotionalSectionBorder'>
                <PromotionalSection />
            </div>

            <Footer />
        </div>
    );
}


export default EventsPage;