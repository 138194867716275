import React from 'react';
import { NavBarOne  } from "master-react-lib";
import Logo from '../../assets/imgs/logo.png';
import MenuIcon from '../../assets/imgs/menuIcon.svg';
import CloseIcon from '../../assets/imgs/closeIcon.svg';





function Header() {

  const menuData = [
    {
      label: "HOME",
      link: "/",
      //link:"https://www.google.com/",
    },

    {
      label: "ABOUT US",
      link: "/about-us",
    },

    {
      label: "SERVICES",
      to: "/",
      //link:"https://www.google.com/",
      hasChildren: true,
      children: [
        { label: "DAILY AND JUMU'AH PRAYERS", link:"/about-us#services" },
        { label: "RAMADHAN IFTAR AND TARAWIH", link:"/about-us#services"},
        { label: "EID PRAYERS", link:"/about-us#services"},
        { label: "MONTHLY/WEEKLY FAMILY NIGHT", link:"/about-us#services"},
      ]
    },

    
    {
      label: "READS",
      link: "/reads-views",
    },
    {
      label: "VIDEOS",
      link: "/videos",
    },
    {
      label: "EVENTS",
      link: "/events",
    },
    {
      label: "FAQ",
      link: "/faq",
    },

  ];

  return (

    <NavBarOne 
      logo={Logo}
      logoLink="/"
      MenuIcon={MenuIcon}
      CloseIcon={CloseIcon}
      background="#fff"
      maxWidth="1175px"
      LogoSize="220px"
      menuData={menuData}
      MenuStatus={true}
      position="fixed"

      //// Button Style ////
      buttonLebel="DONATE NOW"
      ButtonLink="https://give.deenfund.com/bicny"
      target="blank"
      path="/"
      arrow={false}
      arrowColor="#fff"
      buttoncolor="#FF6200"
      width="auto"
      height="47px"
      border="#ccc 0px solid"
      borderRadius="4px"
      colorlabel="#fff"
      fontWeight="600"
      textAlign="center"

    />
  );
}


export default Header;