import React, { useState, useEffect } from 'react';
import SetionTitle from "../SetionTitle/SetionTitle"
import { Section, CardOne  } from "master-react-lib";

import { getServices } from '../../functions';

import Services_1 from '../../assets/imgs/DEENCONNECT-BICNY-722-Services_1.jpg';
import Services_2 from '../../assets/imgs/DEENCONNECT-BICNY-722-Services_2.jpg';
import Services_3 from '../../assets/imgs/DEENCONNECT-BICNY-722-Services_3.jpg';
import Services_4 from '../../assets/imgs/DEENCONNECT-BICNY-722-Services_4.jpg';



const orgId = process.env.REACT_APP_ORGANIZATION_ID ;

function Services() {

    // useEffect( async () =>{

    //     let salahs = await getServices(process.env.REACT_APP_ORGANIZATION_ID, 'deenweb');
    //     let tempData = [];

    //     if( salahs.data.body.data && Array.isArray( salahs.data.body.data )  ){

    //         salahs.data.body.data.map( element =>{

    //             if( tempData.length < 4 ){
    //                 let object = {
    //                     title: element.service.name, 
    //                     img: element?.service.imageMetaData?.imageUrl,
    //                     Discription: JSON.parse(element.service.description)[0].children[0].text.substring(0, 115) + '...' ,
    //                     link: '',
                        
    //                 };
    //                 tempData.push( object );
    //             }
    //         });

    //         setEventCardOneData(tempData); 
    //     }        

    // }, [] ); 

    // const [cardOneData, setEventCardOneData] = useState([
    //     ]);

    const cardOneData = [
        {
          img:Services_1, 
          title:"DAILY & JUMU’AH PRAYERS", 
          Discription: "The BIC Masjid is open for the 5 daily prayers including the Friday Jumu’ah (congregational) pra...", 
          link:"/about-us#services" 
        },
      
         {
          img:Services_2, 
          title:"RAMADHAN IFTAR & TARAWIH", 
          Discription: "Iftar services are provided for over hundreds of Muslims—brothers and sisters including children...", 
          link:"/about-us#services" 
        },
      
         {
          img:Services_3, 
          title:"EID PRAYERS", 
          Discription: "The Eid-Ul-Fitr and the Eid-Ul-Adha Prayer services offered by the BIC take place in the open fiel...", 
          link:"/about-us#services" 
        },
      
         {
          img:Services_4, 
          title:"HAJJ SEMINAR", 
          Discription: "Brooklyn Islamic Center (BIC) offers Seminars on Hajj for brothers and sisters engaging in the ann...", 
          link:"/about-us#services" 
        },
      
      ];
      


    return (

        <Section
            background="#fff"
            //ContainerWidth=""
            Spacing="45px"

            Container={

                <div >
                    <SetionTitle
                        TitleLight="OUR"
                        TitleBold="SERVICES FOR"
                        TitleLightEnd="THE COMMUNITY"
                        buttonLebel="ALL SERVICES"
                        Link="/about-us#services"
                        ButtonStatus={true}
                    />

                    <CardOne
                        cardOneData={cardOneData}
                        col={3}
                        spacing={2}
                        buttonLabel="READ MORE"
                        hoverbackground="#0052a5"
                    />

                </div>

            }
        />
    );
}


export default Services;


