import React from 'react';
import { GetDirection } from "master-react-lib";
import SetionTitle from "../SetionTitle/SetionTitle"

function GetDirectionSection() {

    const onContactFormSubmit = (contactFormData) => {
        console.log("contactFormData", contactFormData);
    };

    return (

        <div>
            <SetionTitle
                TitleLight="GET"
                TitleBold="DIRECTION"
                ButtonStatus={false}
            />
            <div className='paraSection'>
                <p>We will communicate with you and look forward to hearing you</p>
            </div>
            <GetDirection
                disableBtn="disabled"
                From="Enter Your Address"
                To="722 Church Ave, Brooklyn, Ny 11218, United States"
                ThemeColor="#0053A5"
                //// Button Style ////
                buttonLebel="CALCULATE"
                width="100%"
                height="47px"
                border="#ccc 0px solid"
                borderRadius="4px"
                colorlabel="#fff"
                fontWeight="600"
                textAlign="center"
            />
        </div>
    );
}


export default GetDirectionSection;