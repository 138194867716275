import React, { useState, useEffect } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import Grid from "@material-ui/core/Grid";
import PageTitle from "../PageTitle/PageTitle";
import HadithSection from "../HadithSection/HadithSection";
import DonationCardTwoComp from "../SidebarWidgets/DonationCardTwo";
import DonateBanner from "../DonateBanner/DonateBanner";
import Services from "../Services/Services";
import LatestReadAndViews from "../SidebarWidgets/LatestReadAndViews";
import PromotionalSection from "../PromotionalSection/PromotionalSection";
import "../../assets/css/style.css";
import {
  Section,
  SideBarBody,
  VidoeListingWithSearch,
  VideoPlayerModal,
} from "master-react-lib";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import img from "../../assets/imgs/img.png";
import playIcon from "../../assets/imgs/playIcon.png";
import FacebookFeeds from "../FacebookFeeds/FacebookFeeds";

import { getVideos, getOrganization, getSalahs } from "../../functions";
const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function VideosPage() {
  const Youtube = (function () {
    var video, results;

    var getThumb = function (url, size) {
      if (url === null) {
        return "";
      }
      size = size === null ? "big" : size;
      // results = url.match('[\\?&]v=([^&#]*)');
      // video   = (results === null) ? url : results[1];

      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      video = match && match[7].length == 11 ? match[7] : false;

      if (size === "small") {
        return {
          link: "https://img.youtube.com/vi/" + video + "/2.jpg",
          id: video,
        };
      }
      return {
        link: "https://img.youtube.com/vi/" + video + "/0.jpg",
        id: video,
      };
    };

    return {
      thumb: getThumb,
    };
  })();

  // //Example of usage:

  // var thumb = Youtube.thumb('https://www.youtube.com/watch?v=F4rBAf1wbq4', 'small');

  // console.log(thumb);

  const [videoLink, setVieoLink] = useState("");

  const [videoId, setVideoId] = useState("B_UQ7iKZjRg");

  const openVideoModal = (videoId) => (e) => {
    setVideoId(videoId);
    e.preventDefault();
    setOpen(true);
  };

  const [isOpen, setOpen] = useState(false);

  useEffect(async () => {
    let salahs = await getVideos(orgId, "deenweb");
    let tempData = [];
    let thumb;

    if (salahs.data.body.data && Array.isArray(salahs.data.body.data)) {
      setVieoLink(salahs.data.body.data[0].video.link);

      salahs.data.body.data.map((element) => {
        thumb = Youtube.thumb(element.video.link, "small");

        console.log(thumb);
        let object = {
          img: thumb.link,
          title: element.video.title,
          // subtitle: element.video.title ,
          subtitle: "BIC ADMIN",
          date: element.video.createdAt,
          views: "",
          path: "",
          link: element.video.link,
          onclick: openVideoModal(thumb.id),
        };
        tempData.push(object);
      });

      setVideoListingData(tempData);
    }
  }, []);

  const [videoListingData, setVideoListingData] = useState([]);

  return (
    <div>
      <Header />

      <div className="MainWrapper">
        <PageTitle Title="BIC VIDEOS" />
        <HadithSection />

        <Section
          background="#F4F9FC"
          //ContainerWidth=""
          Spacing="40px"
          Container={
            <div>
              <Grid container spacing={3}>
                <Grid item md={8} sm={8} xs={12}>
                  <div className="readDetailsImgContainer">
                    <iframe
                      width="730"
                      height="411"
                      src={videoLink}
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                  <div className="postDateSection">
                    <li>
                      <PersonOutlinedIcon /> BIC ADMIN
                    </li>
                    <li>
                      <CalendarTodayOutlinedIcon /> MAR 1, 2021
                    </li>
                    <li>
                      <VisibilityOutlinedIcon /> 157 VIEWS
                    </li>
                  </div>

                  <div className="VideoListingContainer">
                    <VidoeListingWithSearch
                      videoListingTwoData={videoListingData}
                      col="6"
                      spacing="2"
                      Thumnailwidth="160px"
                      playicon={playIcon}
                      playiconStatus={false}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "40px",
                    }}
                  >
                    <DonateBanner />
                  </div>
                </Grid>

                <Grid item md={4} sm={4} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={12} sm={12} xs={12}>
                      <DonationCardTwoComp />
                    </Grid>

                    <Grid item md={12} sm={12} xs={12}>
                      <FacebookFeeds />
                    </Grid>

                    <Grid item md={12} sm={12} xs={12}>
                      <SideBarBody
                        background="#fff"
                        border="#E4E8EC 1px solid"
                        SideBarBody={<LatestReadAndViews />}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          }
        />
      </div>

      <Services />
      <DonateBanner />
      <div className="PromotionalSectionBorder">
        <PromotionalSection />
      </div>

      <Footer />
      <VideoPlayerModal
        vidoeID={videoId}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
    </div>
  );
}

export default VideosPage;
