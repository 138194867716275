import React, { createRef, useState, useEffect } from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import Grid from '@material-ui/core/Grid';
import PageTitle from '../PageTitle/PageTitle'
import SetionTitle from "../SetionTitle/SetionTitle"
import DonationCardTwoComp from "../SidebarWidgets/DonationCardTwo"
import ServicesTab from "../ServicesTab/ServicesTab"
import DonateBanner from '../DonateBanner/DonateBanner'
import ContactUs from '../ContactUs/ContactUs'
import Banner from '../CarosalImg/CarosalImg'
import '../../assets/css/style.css'
import { Section, TabOne, } from "master-react-lib";
import GetDirection from '../GetDirection/GetDirection';
import LetsConnectForm from '../LetsConnectForm/LetsConnectForm';
import SuccessAlert from '../Common/SuccessAlert';
import HadithSectionSidebar from "../HadithSectionSidebar/HadithSectionSidebar"


import Slider_1 from '../../assets/imgs/DEENCONNECT-BICNY-722-ChurchAve-NewProject.webp'
import Slider_2 from '../../assets/imgs/DEENCONNECT-BICNY-722-ChurchAve-NewProject-2.webp'
import Slider_3 from '../../assets/imgs/DEENCONNECT-BICNY-722-ChurchAve-NewProject-3.webp'

import axios from 'axios';
function AboutUs() {

    const [open, setopen] = useState(false)
    const [email, setemail] = useState("");


    const images = [
        {
            imgPath: Slider_1,
        },
        {
            imgPath: Slider_2,
        },
        {
            imgPath: Slider_3,
        },
    ];

    const tabItems = [
        {
            title: "ABOUT US",
            link: "#About-us",
        },

        {
            title: "OUR MISSION & VISION",
            link: "#mission"
        },

        {
            title: "BIC SERVICE",
            link: "#our-services-for-the-community"
        },

        {
            title: "CONTACT US",
            link: "#contact-us"
        },

        {
            title: "GET DIRECTIONS",
            link: "#get-direction"
        },


    ];




    const submitContactus = (contactUsData) => {
        // const { name, email, message, inquiryType } = contactUsData;
        // const backendapiURL = '';
        // const postData = {
        //     'fullName': name,
        //     'message': message,
        //     'inquiryType': inquiryType,
        //     'email': email,
        //     'stream': 'bicny.org',
        //     'orgId': process.env.REACT_APP_ORGANIZATION_ID,
        //     'name': name
        // };

        console.log('Dawood', contactUsData);

        // if (message, email) {
        //     axios
        //         .post(
        //             backendapiURL + '/contact', postData
        //         )
        //         .then(res => {
        //             console.log('contact ', res.data);

        //         })
        //         .catch(err => console.log('err', err))
        // }
    }

    const servicesRef = createRef();
    const contactRef = createRef();
    const directionRef = createRef();
    const missionRef = createRef();


    const showAlert = (value, email) => {
        setopen(value)
        setemail(email)

        setTimeout(() => {
            setopen(false)
        }, 5000);
    }

    useEffect(() => {
        setTimeout(() => {
            if (window.location.href.indexOf("/about-us#services") > -1) {
                servicesRef && servicesRef.current.scrollIntoView({ behavior: "smooth" });

            } else if (window.location.href.indexOf("/about-us#contact-us") > -1) {
                contactRef && contactRef.current && contactRef.current.scrollIntoView({ behavior: "smooth" });
            }
            else if (window.location.href.indexOf("/about-us#get-direction") > -1) {
                directionRef && directionRef.current.scrollIntoView({ behavior: "smooth" });
            }

            else if (window.location.href.indexOf("#mission") > -1) {
                missionRef && missionRef.current.scrollIntoView({ behavior: "smooth" });
            }
            else {
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth', });
            }
        }, 1000);

    }, [servicesRef, contactRef, directionRef ,missionRef]);

    return (

        <div>
            <Header />

            {open && <SuccessAlert msg={`Thank You For Contacting Us! You will receive an email confirmation shortly at ${email}`} open={open} setOpen={setopen} />}


            <div className="MainWrapper">

                <PageTitle
                    Title="ABOUT US"
                />

                <div className="aBoutUsTabsContainer">
                    <TabOne
                        tabItems={tabItems}
                    />
                </div>

                <Section
                    background="#F4F9FC"
                    //ContainerWidth=""
                    Spacing="40px"

                    Container={
                        <div id="About-us">
                            <Grid container spacing={3}>

                                <Grid item md={8} sm={8} xs={12} className='aboutbanner'>
                                    <Banner
                                        images={images}
                                    />

                                    <div className='paraSection' style={{ marginTop: "20px", }}>

                                        <SetionTitle
                                            TitleLight="ABOUT"
                                            TitleBold="US"
                                            ButtonStatus={false}
                                        />

                                        <p>Brooklyn Islamic Center (BIC) is a 501(C) not-for-profit religious organization. IT was incorporated in the State of New York.
                                            The present facility located at 722 Church Avenue. Brooklyn, New York was pur chased in 2014 and renovated.
                                            Since then BIC has been serving the Muslim community of Kensington and Flatbush areas and beyond in Brooklyn.</p>

                                        <p>New York 11218, in order to build a multipurpose facility, which will serve the multifaceted needs of the
                                            community, It was possible due to fund generated through the generous contribution of sadaqah and
                                            qard-i-hasanah [ non-interest bearing loadn ] by the Muslim community of Brooklyn.</p>


                                    </div>


                                    <div id='mission' ref={servicesRef} className='paraSection'>

                                        <SetionTitle
                                            TitleLight=" OUR"
                                            TitleBold="MISSION & VISION"
                                            ButtonStatus={false}
                                        />

                                        <p>Our mission is, “To provide multifaceted services to the Muslim community of Brooklyn and beyond that will fulfill their
                                            religious, social, cultural, educational, and recreatinal needs through building and maintaining appropriate facilities.”</p>

                                    </div>
                                </Grid>

                                <Grid item md={4} sm={4} xs={12}>
                                    <HadithSectionSidebar />
                                    <DonationCardTwoComp />
                                </Grid>

                            </Grid>
                        </div>
                    }
                />

                <div ref={servicesRef}>
                    <Section
                        background="#fff"
                        //ContainerWidth=""
                        Spacing="40px"

                        Container={
                            <div>
                                <ServicesTab />
                                <div className='DonationBannerSection'>
                                    <DonateBanner />
                                </div>

                            </div>
                        }
                    />


                </div>

                <div id="contact-us">
                    <Section
                        background="#F4F9FC"
                        //ContainerWidth=""
                        Spacing="40px"

                        Container={
                            <div ref={contactRef}>
                                <Grid container spacing={3}>

                                    <Grid item md={12} sm={12} xs={12}>
                                        <LetsConnectForm
                                            onContactFormSubmit={submitContactus}
                                            showAlert={showAlert}
                                        />
                                    </Grid>

                                </Grid>
                            </div>
                        }
                    />
                </div>

                <div >
                    <Section
                        background="#fff"
                        //ContainerWidth=""
                        Spacing="40px"

                        Container={
                            <div ref={directionRef}>
                                <Grid container spacing={3}>

                                    <Grid item md={7} sm={7} xs={12}>
                                        <GetDirection />
                                    </Grid>

                                    <Grid item md={5} sm={5} xs={12}>

                                    </Grid>

                                </Grid>
                            </div>
                        }
                    />
                </div>



            </div>

            <Footer />
        </div>
    );
}


export default AboutUs;