import React, { useEffect, useState } from 'react';
import { SmallPostCardOne, ButtonOne } from "master-react-lib";
import moment from 'moment';
import img from '../../assets/imgs/img.png';
import playIcon from '../../assets/imgs/playIcon.png';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { getNews } from '../../functions';
const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function LatestReadAndViews() {

    const click = () => {
        console.log("Back Button Clicked");
    };

    useEffect(async () => {
        let news = await getNews(orgId, 'deenweb');
        let tempNews = [];

        if (news && news.data.body.data && Array.isArray(news.data.body.data)) {
            //get the news items and process them into array 
            news.data.body.data.filter( x => x.status === "Public" ).map(element => {
                if (tempNews.length < 4) {
                    let object = {
                        name: element.article?.author,
                        date: moment(element.article?.createdAt).format('MMMM Do YYYY'), // 'MAY 05, 2020',
                        img: element.article.imageMetaData?.imageUrl,
                        subtitle: element.article.author,
                        title: element.article.title.substring(0, 50),
                        Discription: JSON.parse(element.article.description)[0].children[0].text.substring(0, 90) + '...',
                        path: `/reads-details?q=${element.article._id}`
                    };
                    tempNews.push(object);
                }
            });
            setSmallPostCardOneData(tempNews);


        }
    }, []);

    const [SmallPostCardOneData, setSmallPostCardOneData] = useState([

        {
            img: img,
            title: "Story of a Ruler and a Scholar",
            subtitle: "BIC ADMIN",
            date: "MAR 10, 2021",
            path: "",
        }
    ]);


    return (
        <div>
            <div className='sideBarSectoinTitle'>LATEST READS/VIEWS</div>
            <SmallPostCardOne
                SmallPostCardOneData={SmallPostCardOneData}
                col="12"
                spacing="1"
                Thumnailwidth="83px"
                ThumnailHeight="83px"
                titleWieght="600"
                titleColor="#0B132B"
                playicon={playIcon}
                playiconStatus={false}
                borderLine={true}
                thumnailRadius="4px"
                titletextTransform="uppercase"
            />
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px", }}>
                <ButtonOne
                    label="MORE VIEWS"
                    onClick={click}
                    link="/reads-views"
                    //target="blank"
                    path="/"

                    arrowIcon={<ArrowForwardIosRoundedIcon />}
                    arrowStatus={true}
                    arrowColor="#0B132B"

                    buttoncolor="#fff"
                    width=""
                    height="50px"
                    border="#0053A5 1px solid"
                    borderRadius="4px"
                    color="#0B132B"
                    fontWeight="600"
                    textAlign="center"
                />
            </div>
        </div>
    );
}


export default LatestReadAndViews;