import React from 'react';
import DonationBanner from '../../assets/imgs/Donationbanner.png'


function DonateBanner() {

    return (

       <div className="DonationbannerContainer">
           <a href="https://give.deenfund.com/bicny" target="_blank"><img src={DonationBanner} alt="Donation"/></a>
       </div>
    );
}


export default DonateBanner;