import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Section, BlogOne } from "master-react-lib";
import CarosalImg from '../CarosalImg/CarosalImg'

import Slider_1 from '../../assets/imgs/DEENCONNECT-BICNY-722-ChurchAve-NewProject.webp'
import Slider_2 from '../../assets/imgs/DEENCONNECT-BICNY-722-ChurchAve-NewProject-2.webp'
import Slider_3 from '../../assets/imgs/DEENCONNECT-BICNY-722-ChurchAve-NewProject-3.webp'


function PromotionalSection() {

    const blogOneData = [
        {   
            titleLight: "YOUR SECOND HOME ",
            titleBold: "LET'S BUILD TOGETHER",
            description: <div><p>12000 SQ. FT. space at the corner of Church Ave & E8 St will facilitate the community with a second home.</p> <p>The Prophet (peace be upon him) said: “Whoever builds a mosque for Allah, Allah will build for him likewise in Paradise. [Sahîh al-Bukhârî and Sahîh Muslim]</p></div>,
        }

    ];


    const images = [
        {
            imgPath: Slider_1
        },
        {
            imgPath: Slider_2
        },
        {
            imgPath: Slider_3
        },

    ];


    return (

        <Section
            background="#0053A5"
            //ContainerWidth=""
            Spacing="45px"

            Container={


                <div >

                    <Grid container spacing={3}>

                        <Grid item md={5} xs={12}>

                            <CarosalImg
                                images={images}
                            />

                        </Grid>

                        <Grid item md={7} xs={12}>
                            <BlogOne
                                col="12"
                                Spacing="40px"
                                blogOneData={blogOneData}
                                color="#fff"
                                titleLightFontSise="34px"
                                titleBoldFontSise="34px"
                                descriptionFontSise="13px"

                                //// Button Style ////
                                buttonLebel="VIEW DETAILS"
                                link="https://build.bicny.org/"
                                target="blank"
                                path="https://build.bicny.org/"
                                arrow={false}
                                arrowColor="#fff"
                                buttoncolor="none"
                                width="auto"
                                height="47px"
                                border="#fff 1px solid"
                                borderRadius="4px"
                                colorlabel="#fff"
                                fontWeight="600"
                                textAlign="center"


                            />

                        </Grid>



                    </Grid>






                </div>

            }
        />
    );
}


export default PromotionalSection;


