import React from 'react';
import { ReserveSectionOne} from "master-react-lib";
import ReserveSectionOneBg from "../../assets/imgs/ReserveSectionOneBg.jpg"


function ReserveYourToday() {

    const OnclickDownload = () => {
        console.log("Back Button Clicked");
    };

    return (

        <ReserveSectionOne
            title=" RESERVE GRAVE IN MUSLIM CEMETERY"
            description="And the life of this world is nothing but play and amusement. But far better is the house in the hereafter for those who are Al-Muttaqun (the pious). Will you not then understand?"
            reference="(Quran, Al-An’aam: 32)"
            OnclickDownload={OnclickDownload}
            background={ReserveSectionOneBg}
            buttonLabel="CONTACT US"
            Textcolor="#fff"

            //// Button Style ////
            buttonLebel="CONTACT US"
            buttonLink="/about-us#contact-us"
            //target="blank"
            arrow={false}
            arrowColor="#fff"
            buttoncolor="#0053A5"
            width="auto"
            height="47px"
            border="#ccc 0px solid"
            borderRadius="4px"
            colorlabel="#fff"
            fontWeight="600"
            textAlign="center"
        />

    );
}


export default ReserveYourToday;


