import React, { useState, useEffect } from 'react';
import SetionTitle from "../SetionTitle/SetionTitle"
import Grid from '@material-ui/core/Grid';
import { Section, CardTwo, ButtonOne, DonationCardOne } from "master-react-lib";
import img from '../../assets/imgs/readimg.png';
import DonateCardOneComp from "../SidebarWidgets/DonateCardOne";
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { getNews } from '../../functions';
const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function ReadandViews() {

    useEffect(async () => {
        let news = await getNews(orgId, 'deenweb');
        let tempNews = [];

        if (news && news.data.body.data && Array.isArray(news.data.body.data)) {
            //get the news items and process them into array 
            news.data.body.data.filter( x => x.featured === true )
            .sort( (a, b) => a.article.order - b.article.order )
            .map(element => {
                if (tempNews.length < 3) {
                    let object = {
                        name: element.article?.author,
                        date: element.article?.createdAt, // 'MAY 05, 2020',
                        img: element.article.imageMetaData?.imageUrl,
                        title: element.article.title.substring(0, 50),
                        Discription: JSON.parse(element.article.description)[0].children[0].text.substring(0, 110) + '...',
                        link: "/reads-details?q="+ element.article._id 
                    };
                    tempNews.push(object);
                }
            });

            setCardTwoData(tempNews);

        }
    }, []);

    const [cardTwoData, setCardTwoData] = useState([


    ]);

    return (

        <Section
            background="#fff"
            //ContainerWidth=""
            Spacing="45px"

            Container={


                <div >

                    <Grid container spacing={3}>

                        <Grid item md={9} xs={12}>
                            <SetionTitle
                                TitleLight="LATEST"
                                TitleBold="READS/VIEWS"
                                buttonLebel="ALL NEWS"
                                Link="/reads-views"
                                //target="new"
                                ButtonStatus={true}
                            />
                            <CardTwo
                                cardTwoData={cardTwoData}
                                col="4"
                                Imgcol="12"
                                Contentcol="12"
                                spacing={2}
                                buttonLabel="READ MORE"
                                hoverbackground="#0052a5"
                                titleColor="#0053a5"
                                nameDateStatus={false}
                            />
                        </Grid>

                        <Grid item md={3} xs={12}>

                            <Grid container spacing={2}>

                                <Grid item md={12} xs={12}>
                                    <ButtonOne
                                        label="SUPPORT OUR CAUSES"
                                        link="https://give.deenfund.com/bicny"
                                        target="blank"
                                        path="/"
                                        arrowIcon={<ArrowForwardIosRoundedIcon />}
                                        arrowStatus={true}
                                        arrowColor="#fff"
                                        buttoncolor="#0053A5"
                                        width="100%"
                                        height="50px"
                                        border="#ccc 0px solid"
                                        borderRadius="4px"
                                        color="#fff"
                                        fontWeight="600"
                                    />
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <ButtonOne
                                        label="DONATE NOW"
                                        link="https://give.deenfund.com/bicny"
                                        target="blank"
                                        path="/"
                                        arrowIcon={<ArrowForwardIosRoundedIcon />}
                                        arrowStatus={true}
                                        arrowColor="#fff"
                                        buttoncolor="#0053A5"
                                        width="100%"
                                        height="50px"
                                        border="#ccc 0px solid"
                                        borderRadius="4px"
                                        color="#fff"
                                        fontWeight="600"
                                    />
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <ButtonOne
                                        label="CONTACT US"
                                        link="/about-us#contact-us"
                                        //target="new"
                                        path="/"
                                        arrowIcon={<ArrowForwardIosRoundedIcon />}
                                        arrowStatus={true}
                                        arrowColor="#fff"
                                        buttoncolor="#0053A5"
                                        width="100%"
                                        height="50px"
                                        border="#ccc 0px solid"
                                        borderRadius="4px"
                                        color="#fff"
                                        fontWeight="600"
                                    />
                                </Grid>

                                <Grid item md={12} xs={12}>
                                    <DonateCardOneComp />
                                </Grid>

                            </Grid>

                        </Grid>

                    </Grid>






                </div>

            }
        />
    );
}


export default ReadandViews;


