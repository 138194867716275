import React, { useState, useEffect } from 'react';
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import Grid from '@material-ui/core/Grid';
import PageTitle from '../PageTitle/PageTitle'
import HadithSection from '../HadithSection/HadithSection';
import DonationCardTwoComp from "../SidebarWidgets/DonationCardTwo"
import DonateBanner from '../DonateBanner/DonateBanner'
import ReadsDetailsImg from '../../assets/imgs/reads-details.png'
import Services from '../Services/Services'
import PromotionalSection from '../PromotionalSection/PromotionalSection'
import '../../assets/css/style.css'
import { Section, SideBarBody, } from "master-react-lib";
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import VideoListingSidebar from '../SidebarWidgets/Videos'
import FacebookFeeds from "../FacebookFeeds/FacebookFeeds";
import img from '../../assets/imgs/img.png';
import playIcon from '../../assets/imgs/playIcon.png';
import { getNews, getSingleNews } from '../../functions';
import moment from 'moment';
import { serialize } from '../../utils/markdowntoreact';
const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function ReadsDetailsPage(props) {

    useEffect( async ()=>{

        const queryParams = new URLSearchParams(window.location.search)
        const term = queryParams.get("q")

       
        let news = await getSingleNews(term );
        

        if( news && news.data.body && ( news.data.body.data ) ){
            setNewsData( news.data.body.data );
            console.log( news.data.body.data)
        }
    }, [] );

    const [newsData, setNewsData] = useState({});

    const videoListingTwoData = [

        {
            img: img,
            title: "Story of a Ruler and a Scholar",
            subtitle: "BIC ADMIN",
            date: "MAR 10, 2021",
            views: "157 VIEWS",
            path: "",
        }

    ];

    let x = newsData?.description && JSON.parse(newsData?.description);
    let editor;
    if (x) {
        editor = { children: [...x] }
    }
    const description = newsData?.description && newsData?.description?.includes('"type":') ? serialize(editor) : newsData?.description;

    const additionalContent = newsData?.additionalContent?.map((el, index) => {
        switch (el?.type) {
            case 'video':
                return (<iframe key={index} src={el?.value ?? ""} style={{ width: "100%", minHeight: "500px" }}></iframe>)

            case 'image':
                return <img key={index} style={{ width: "100%", minHeight: "500px" }} src={el?.value?.imageUrl ?? ""} />

            case 'text':
                let x = el?.value && JSON.parse(el?.value);
                let editor;
                if (x) {
                    editor = { children: [...x] }
                }
                const description = el?.value && el?.value?.includes('"type":') ? serialize(editor) : el?.value;
                return <p dangerouslySetInnerHTML={{ __html: description }}></p>
        }

    })


    return (

        <div>
            <Header />


            <div className="MainWrapper">

                <PageTitle
                    Title="READS/VIEWS"
                />
                <HadithSection />


                <Section
                    background="#F4F9FC"
                    //ContainerWidth=""
                    Spacing="40px"

                    Container={
                        <div>

                            <Grid container spacing={3}>

                                <Grid item md={8} sm={8} xs={12}>
                                    <div className='readDetailsImgContainer'>
                                        <img src={newsData?.imageMetaData?.imageUrl} />
                                    </div>
                                    <div className='ArticleTitle'>{newsData?.title}</div>
                                    <div className='postDateSection'>
                                        <li><PersonOutlinedIcon />{newsData?.author}</li>
                                        <li><CalendarTodayOutlinedIcon />{moment(newsData?.createdAt).format('MMM Do YYYY')}</li>
                                    </div>

                                    <div className='paraSection'>
                                        <p dangerouslySetInnerHTML={{ __html: description }}></p>
                                        {additionalContent}
                                        {/* {newsData?.description ? JSON.parse( newsData.description)[0].children[0].text : '' }
                                        {newsData?.description ? JSON.parse( newsData.description)[1]?.children[0]?.text : '' } */}
                                    </div>
                                </Grid>

                                <Grid item md={4} sm={4} xs={12}>

                                    <Grid container spacing={3}>

                                        <Grid item md={12} sm={12} xs={12}>
                                            <FacebookFeeds />
                                        </Grid>

                                        <Grid item md={12} sm={12} xs={12}>
                                            <DonationCardTwoComp />
                                        </Grid>

                                        <Grid item md={12} sm={12} xs={12}>
                                            <SideBarBody
                                                background="#fff"
                                                border="#E4E8EC 1px solid"
                                                SideBarBody={
                                                    <VideoListingSidebar />
                                                }
                                            />
                                        </Grid>
                                    </Grid>


                                </Grid>
                            </Grid>

                        </div>
                    }
                />



            </div>

            <Services />
            <DonateBanner />
            <div className='PromotionalSectionBorder'>
                <PromotionalSection />
            </div>

            <Footer />
        </div>
    );
}


export default ReadsDetailsPage;