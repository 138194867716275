import React, {useEffect} from 'react';
import Alert from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';


function SendMessageAlert(props) {

    const [showElement, setShowElement] = React.useState(true)
    useEffect(() => {
        setTimeout(function () {
            setShowElement(false)
        }, 3000);
    },
        [])

    return (
        <>
            {showElement ? <div className='alertBox'>
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                    Your message has been sent. Thank you.
                </Alert>
            </div> : <></>}

        </>
    );
}


export default SendMessageAlert;


