import React, { useState, useEffect } from 'react';
import { FooterTwo, FooterBottomOne  } from "master-react-lib";
import DCLogo from '../../assets/imgs/DC_Logo.png';
import VideoCam from '../../assets/imgs/videocam.svg'
import location from '../../assets/imgs/location.svg'
import call from '../../assets/imgs/call.svg'
import email from '../../assets/imgs/email.svg'
import Locationicon from '../../assets/imgs/Locationicon.svg'

import facebook from '../../assets/imgs/facebook-icon.png'
import twitter from '../../assets/imgs/twitter-icon.png'
import youtube from '../../assets/imgs/youtube-icon.png'
import moment from 'moment';

import { getVideos, getOrganization, getSalahs } from '../../functions';
const orgId = process.env.REACT_APP_ORGANIZATION_ID ; 


function Footer( props ) {

    const getTimeStamp = (time) => {
        let timestamp = moment(time).format("x");
        return timestamp
    }

    useEffect( async () =>{

        const resSalahGet = await getSalahs(orgId, 'deenweb')

        const SalahData = resSalahGet?.data?.body?.data

        const ActiveSalah = SalahData.filter(text => text.status === 'true')


        const resOrg = await getOrganization(orgId);
        const orgIntegate = resOrg.data?.body?.data?.enabledIntegrations;

        //set the footer org social links 
        let socialLinks = []; 
        let object; 
        if(resOrg && resOrg.data && resOrg.data.body.data.orgSocials){
            if( resOrg.data.body.data.orgSocials.facebook){
                object = {
                    icon: facebook, 
                    link: resOrg.data.body.data.orgSocials.facebook
                }; 
                socialLinks.push( object );
            }
            if( resOrg.data.body.data.orgSocials.twitter){
                object = {
                    icon: twitter, 
                    link: resOrg.data.body.data.orgSocials.twitter
                }; 
                socialLinks.push( object );
            }
            if( resOrg.data.body.data.orgSocials.youtube){
                object = {
                    icon: youtube, 
                    link: resOrg.data.body.data.orgSocials.youtube
                }; 
                socialLinks.push( object );
            }

            setFooterSocial( socialLinks );
        }
        
        // console.log(orgIntegate)

        let salah = orgIntegate?.deenweb?.salahs?.selectedSalahs
        
        const d1 = new Date();
        const d = Number(d1)
        const resData = salah.filter((item => item.status === "Public"));  //&& Number(new Date(item.publishFrom)) < d && Number(new Date(item.publishTo)) > d)
        console.log("salah with App ID: ", resData)
            
        const Ids = resData.length ? resData.map(t => t.salah) : [];
        // console.log("Active Salah")
        // console.log(Ids)
        
        let updatedSalahObj = []

        const SalahToDisplay = ActiveSalah.filter(text => Ids.includes(text._id))
        
        //console.log("SalahToDisplay: ", SalahToDisplay)

        let sortedSalah = SalahToDisplay?.filter((el) => el?.order).sort((a, b) => {
                return a.order - b.order
        })

        const lu = sortedSalah?.length ? sortedSalah.sort((a, b) => {

            return getTimeStamp(a.updatedAt) - getTimeStamp(b.updatedAt)
        }) : []


        
        let tempData = [];

        if( lu  && Array.isArray( lu  )  ){

            // setlastUpdated(lu?.[0]?.updatedAt)

            lu.map( element =>{

                let name = element?.salahName?.fullName?.split("(")
                            
                name = name?.length ? name[0] : salah?.salahName.fullName;
                let salahTime = element?.salahStart?.iqamahTime ? moment(element?.salahStart?.iqamahTime).utcOffset(0).format('LT') : "";
                    
                    object = {
                        col1: name, 
                        col2: salahTime
                    };
                    tempData.push( object );               

                
            });

            setTablebodyData(tempData); 
        }       

        let salahs = await getVideos(orgId, 'deenweb');
        tempData = [];

        if( salahs.data.body.data && Array.isArray( salahs.data.body.data )  ){

            salahs.data.body.data.map( element =>{

                // let date2 = new Date(element.salahStart.iqamahTime.slice(0, -1));
                if( tempData.length < 3 ){
                    let object = {
                        Title: element.video.title, 
                        subtitle: element.video.title,
                        icon: VideoCam
                    };
                    tempData.push( object );
                }
                
            });

            setFooterTwoColTwo(tempData); 
        }        

    }, [] ); 

    const FooterTwoColOne = [
        { description: "BIC provides weekly jumu’ah prayer facilities to the muslims of Kensington, Flatbush, and Prospect Park.", },
        { description: "Brooklyn Islamic Center is a 501(c)- Non-Profit Tax ID 16-1621062", }
    ];

    const [FooterTwoSocial, setFooterSocial] = useState([
        { icon: facebook, link: "https://www.facebook.com/BrooklynIslamicCenterBIC/" },        
        { icon: youtube, link: "https://www.youtube.com/channel/UCM7Uy6rv46iMTRXHWG6FNkw" },
    ]);

    const [FooterTwoColTwo, setFooterTwoColTwo] = useState( []);

    const FooterTwoContactinfo = [
        { TextOne: "722 Church Ave", TextTwo: "Brooklyn, NY 11218", icon: location },
        { TextOne: "718-469-4899", TextTwo: "929-340-0399", icon: call },
        { TextOne: "team@bicny.org", icon: email },

    ];

    const FooterTwoGiveUs = [
        { TextOne: "Give us a visit and earn the Hasanah of praying together in Jam’at !", icon: Locationicon },
    ];

    const [tableBodyData, setTablebodyData ] = useState( [
               
    ]);

    const d = new Date().toLocaleString();


    const click = () => {
        console.log("Form btn");
    };

    return (
        <div>
            <FooterTwo
                background="#0053A5"
                color="#FFFFFF"
                listingBorder="#0868C9 1px solid"
                FooterTwoColOne={FooterTwoColOne}
                FooterTwoColTwo={FooterTwoColTwo}
                FooterTwoSocial={FooterTwoSocial}
                FooterTwoContactinfo={FooterTwoContactinfo}
                FooterTwoGiveUs={FooterTwoGiveUs}
                ContainerMaxWidth="1175px"

                ColTitleOne="ABOUT US"
                ColTitleTwo="RECENT VIDEOS"
                ColTitleThree="SALAT/ IQAMAH TIME SCHEDULE"
                SalatiqamaDataTime={d}
                ColTitleFour="CONTACT INFO"
                ColTitleFive="GIVE US A VISIT"
                ColTitleSix="SUBSCRIBE FOR NEWSLETTER"
                FormDescription="SUBSCRIBE NOW for the latest news, articles, videos & much more from our awesome website!"


                ////Slat Table Style ////
                tableBodyData={tableBodyData}
                odd="#005EBB"
                even="#0868C9"
                colBorder="#E4E8EC 0px solid"
                col1Color="#fff"
                col2Color="#fff"
                ColPadding="9px 30px !important"

                //// Button Style ////
                ContactbuttonLebel="CONTACT US"
                ContactLink="/about-us#contact-us"

                getDirectionbuttonLebel="GET DIRECTION"
                getDirectiontLink="/about-us#get-direction"

                formButtonLabel="SUBSCRIBE NOW"
                onClick={click}

                //target="blank"
                arrow={false}
                arrowColor="#fff"
                buttoncolor="#fff"
                width="auto"
                height="50px"
                border="#ccc 0px solid"
                borderRadius="4px"
                colorlabel="#0053A5"
                fontWeight="600"
                textAlign="center"
                FooterRowTwo={true}

            />

            <FooterBottomOne
                bordercolor="#0053A5"
                Container="1170px"
            />

        </div>
    );
}


export default Footer;