import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router';
// import SuccessAlert from '../Common/SuccessAlert';
import { PhoneInputField } from "master-react-lib";
import { getOrganization } from '../../functions';
import SendMessageAlert from './SendMessageAlert'

import SetionTitle from "../SetionTitle/SetionTitle"
import Grid from '@material-ui/core/Grid';
import AddressBG from "../../assets/imgs/address-background.jpg"
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import CallRoundedIcon from '@material-ui/icons/CallRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';

const TEST_SITE_KEY = process.env.REACT_APP_CAPTCHA_KEY;
const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
class LetsConnectForm extends React.Component {
    constructor(props) {
        super(props);
        this.sectionRef = React.createRef();
        this.state = {
            inquiryTypes: [],
            captcha: '',
            phoneError: false,
            selectedInquiries: [],
            contactData: {
                name: '',
                email: '',
                inquiryType: '',
                message: '',
                stream: process.env.REACT_APP_CONTACT_STREAM,
                orgId: process.env.REACT_APP_ORGANIZATION_ID,
                section: "",
                phone: ''
            },
            showThankYou: false,
            open: false
        }
    }

    async componentDidMount() {
        if (this.props.scrollTo === "connect-form") {
            this.sectionRef.current.scrollIntoView({ behavior: "smooth" });
        }

        console.log('Org Data Called')
        const orgId = process.env.REACT_APP_ORGANIZATION_ID;
        //const token = localStorage.getItem("authToken");
        try {
            const response = await getOrganization(orgId);
            const data = response.data.body.data;
            console.log("response: ", response)
            const pagesAll = data?.enabledIntegrations?.deenweb?.pages;
            const webContactInfo = data?.enabledIntegrations?.deenweb?.settings?.contactDetails ?? {};

            let inquiryTypesData = [];
            let a = data?.contactInfo?.forEach(contact => {
                let b = contact?.inquiryTypes?.forEach(inq => {
                    inquiryTypesData.push(inq)
                });
            });

            const data2 = inquiryTypesData?.length ? inquiryTypesData?.map((el) => { return { id: el._id, primary: el.inquiryName, data: { ...el } } }) : [];
            //console.log("data2: ", data2)

            const Ids = webContactInfo?.inquiryTypes.length ? webContactInfo?.inquiryTypes.map(t => t._id) : [];
            //console.log("Ids: ", Ids)

            const selectedInq = data2.filter(inq => Ids.includes(inq.id))

            // this.state.selectedInquiries = selectedInq;
            this.setState({
                selectedInquiries: selectedInq
            });
            //console.log("selectedInq: ", this.state.selectedInquiries)
        }
        catch (err) {
            // setLoader(false);
            // seterror(err.response && err.response.data.message)
            // setTimeout(() => {
            //   seterror(false)
            // }, 8000);
        }
        // Add reCaptcha
        const script = document.createElement("script")
        script.src = `https://www.google.com/recaptcha/api.js?render=${TEST_SITE_KEY}`
        document.body.appendChild(script)
    }

    setPhone = (phone) => {
        this.setState(prevState => ({
            contactData: {
                ...prevState.contactData,
                phone: phone
            }
        }));
    }

    changeHandler = (e) => {
        console.log("contactData: ", this.state.contactData)
        if (e.target.name === "message" && e.target.value.length > 300) {
            return;
        } else {
            e.persist();
            this.setState(prevState => ({
                contactData: {
                    ...prevState.contactData,
                    [e.target.name]: e.target.value
                }
            }));
        }
    }



    submitHandler = (e) => {
        e.preventDefault();



        if (true) {
            let { name, message, inquiryType, email, phone } = this.state.contactData;
            if (phone.length > 3) {
                const validatePhone = phoneRegex.test(phone);
                if (!validatePhone) {
                    this.setState({ phoneError: true });
                } else {
                    this.setState({ phoneError: false });
                }
            } else {
                this.setState(prevState => ({
                    contactData: {
                        ...prevState.contactData,
                        phone: ''
                    },
                    phoneError: false
                }));
            }
            if (name && message && inquiryType && email && !this.state.phoneError) {
                console.log("API CALLED....")
                name = name?.split(/(?<=^\S+)\s/)
                axios
                    .post(
                        process.env.REACT_APP_BASEURL_DEV_API + '/contact', {
                        ...this.state.contactData,
                        name: {
                            firstName: name[0], lastName: name[1]
                        }
                    }
                    )
                    .then(res => {
                        this.props.showAlert(true, email)
                        localStorage.setItem("contactMail", email)
                        console.log('contact ', res);
                        this.setState({
                            contactData: {
                                name: '',
                                email: '',
                                inquiryType: '',
                                message: '',
                                phone: '',
                                stream: process.env.REACT_APP_CONTACT_STREAM,
                                orgId: process.env.REACT_APP_ORGANIZATION_ID,
                            },
                        })
                    })
                    .catch(err => console.error(err))
            }
        }


    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }


    render() {
        const inqTypes = this.state.selectedInquiries //this.props.organization?.enabledIntegrations?.deenweb?.settings?.contactDetails?.inquiryTypes ?? []
        console.log("inqTypes: ", inqTypes)
        const { contactData } = this.state;
        const { name, message, inquiryType, email } = contactData;
        let disableBtn = name && message && inquiryType && this.validateEmail(email) ? false : true;

        return (

            <>
           
                {/* <SendMessageAlert /> */}
                <div className="section" ref={this.sectionRef}>



                    <Grid container spacing={3}>

                        <Grid item xs={12} sm={7} md={7}>
                            <SetionTitle
                                TitleLight="CONTACT"
                                TitleBold="US"
                                ButtonStatus={false}
                            />
                            <p className='ContactUssubText'>We will communicate with you and look forward to hearing you</p>


                            <Grid container spacing={3}>

                                <Grid item xs={12} sm={6} md={6}>
                                    <div className='ContactfieldCtn'>
                                        <label className='ContactfieldLabel'>Full Name</label>
                                        <input type="text" className="form-control Contactfield" id="label-name" placeholder="Enter your full name"
                                            name="name"
                                            onChange={this.changeHandler}
                                            value={contactData.name}
                                            required />
                                    </div>
                                </Grid>


                                <Grid item xs={12} sm={6} md={6}>
                                    <div className='ContactfieldCtn'>
                                        <label className='ContactfieldLabel'>Phone Number (Optional)</label>
                                        <PhoneInputField
                                            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                                            country={'us'}
                                            value={this.state?.contactData?.phone}
                                            countryCodeEditable={false}
                                            onChange={(phone, country) => this.setPhone(phone)}
                                            labelFixed="Phone Number (Optional)"
                                            errorText={"Phone number is correct"}
                                            error={this.state.phoneError}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    <div className='ContactfieldCtn'>
                                        <label className='ContactfieldLabel'>Inquiry Type</label>
                                        <select className="browser-default custom-select "
                                            defaultValue={'select'}
                                            name="inquiryType"
                                            onChange={this.changeHandler}
                                            value={contactData.inquiryType}
                                        >
                                            <option value='' selected>Inquiry Types</option>
                                            {inqTypes?.map((el, i) => {

                                                return <option key={i} value={el.data.inquiryName}>{el.data.inquiryName}</option>
                                            })}
                                        </select>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={6} md={6}>
                                    <div className='ContactfieldCtn'>
                                        <label className='ContactfieldLabel'>Email</label>
                                        <input type="text" className="form-control Contactfield" id="label-name1" placeholder="Enter your Email Address"
                                            name="email"
                                            onChange={this.changeHandler}
                                            value={contactData.email}
                                            required />
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <div className='ContactfieldCtn'>
                                        <label className='ContactfieldLabel'>Message</label>
                                        <textarea className="text_area form-control" maxLength="300" minLength="40" required placeholder="Enter your message here..." name="message" onChange={this.changeHandler} value={contactData.message}></textarea>
                                        <p className="text_limit_hint">CHAR {contactData?.message?.length ?? 0}/300</p>
                                    </div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <div className='ContactfieldCtn'>
                                        <button className={`btn_submit buttonFilled ${disableBtn ? "disabled" : ""}`} onClick={this.submitHandler} disabled={disableBtn}>SEND MESSAGE</button>
                                    </div>
                                </Grid>




                            </Grid>

                        </Grid>

                        <Grid item xs={12} sm={5} md={5}>
                            <div className='AdressSection'>
                                <img src={AddressBG} />

                                <div className='address_info_col'>
                                    <li><LocationOnRoundedIcon /> <span>722 Church Ave Brooklyn, NY 11218</span></li>
                                    <li><CallRoundedIcon /> <span>718-469-4899<br /> 929-340-0399</span></li>
                                    <li><EmailRoundedIcon /> <span>team@bicny.org</span></li>
                                </div>
                            </div>
                        </Grid>

                    </Grid>

                </div>

            </>


        );
    }
}

export default withRouter(LetsConnectForm);