import React from 'react';
import { SideBarBody } from "master-react-lib";

function FacebookFeeds() {


    return (

        <SideBarBody
            background="#fff"
            border="#E4E8EC 1px solid"
            SideBarBody={
                <div className='SocialToCtn'>
                    <h4 className='sideBarSectoinTitle'>WE’RE SOCIAL TOO</h4>
                    <div class="fb-page" data-href="https://www.facebook.com/BrooklynIslamicCenterBIC/" data-tabs="timeline" data-width="" data-height="" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/BrooklynIslamicCenterBIC/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/BrooklynIslamicCenterBIC/">Brooklyn Islamic Center [BIC]</a></blockquote></div>
                </div>
            }
        />
    );
}


export default FacebookFeeds;


