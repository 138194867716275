import React from "react";
import { CTAEight, ModalThree } from "master-react-lib";

// import Df from "../assets/imgs/df-donation-button.svg";
import Df from "../../assets/imgs/df-donation-button.svg";
// import Paypal from '../assets/imgs/paypal.svg';
import Zelle from "../../assets/imgs/Zelle.png";
import Chase from "../../assets/imgs/Chase.png";

const CTAEightSection = (props) => {
  const [OpenZelle, setOpenZelle] = React.useState(false);
  const handleOpenZelle = () => setOpenZelle(true);
  const ZellehandleClose = () => setOpenZelle(false);

  const [OpenQuickPay, setOpenQuickPay] = React.useState(false);
  const handleOpenQuickPay = () => setOpenQuickPay(true);
  const QuickPayhandleClose = () => setOpenQuickPay(false);

  const [OpenSendCheck, setOpenSendCheck] = React.useState(false);
  const handleSendCheck = () => setOpenSendCheck(true);
  const SendCheckhandleClose = () => setOpenSendCheck(false);

  const cTAEightData = [
    {
      icon: Df,
      link: "https://give.deenfund.com/bicny?utm_source=bicny-df&utm_medium=bicny-web&utm_campaign=bicny-deenfund-web",
      target: "_blank",
    },
    // { icon: Paypal, link: "https://www.paypal.com/us/signin" },

    { icon: Zelle, onClick: handleOpenZelle },
    // { icon: Chase, onClick: handleOpenQuickPay },
    { lebel: "SEND CHECK BY MAIL", onClick: handleSendCheck },
  ];

  return (
    <div>
      <CTAEight
        cTAEightData={cTAEightData}
        backgroundColor="#0153a5"
        backgroundColorRight="#0668b2"
        color="#fff"
        btnlebelcolor="#0153a5"
        Title="MANY WAYS TO SUPPORT BIC"
        SubTitle="Al-Tirmidhi"
        ButtonLebel="DONATE NOW"
        link=""
        target="blank"
        minHeight="250px"
        //=== left col content ===/
        TitleLeft="SUPPORT BIC"
        LeftDescription="The Prophet ﷺ said, the most beloved of deeds to Allah are those that are most consistent, even if it is small."
        bookname="(Sahih Bukhari)"
        LeftBottomText="Please donate $5-$10/ every month Auto recurring by DEENFUND"
      />

      <ModalThree
        open={OpenZelle}
        handleClose={ZellehandleClose}
        width="660px"
        title="DONATE VIA ZELLE"
        titileColor="#0153a5"
        body={
          <div>
            <p>ZELLE EMAIL</p>
            <a href="mailto:salaambic@gmail.com">salaambic@gmail.com</a>
          </div>
        }
      />

      <ModalThree
        open={OpenQuickPay}
        handleClose={QuickPayhandleClose}
        width="660px"
        title="DONATE VIA CHASE QUICKPAY"
        titileColor="#0153a5"
        body={
          <div>
            <p>EMAIL</p>
            <a href="#">HelpCtg.USA@gmail.com</a>
          </div>
        }
      />

      <ModalThree
        open={OpenSendCheck}
        handleClose={SendCheckhandleClose}
        width="660px"
        title="SEND CHECK BY MAIL"
        titileColor="#0153a5"
        body={
          <div>
            <p>Address:</p>
            <a href="#">
              {" "}
              Brooklyn Islamic Center Inc. <br></br>722 Church Ave, Brooklyn, NY
              11218
            </a>
          </div>
        }
      />
    </div>
  );
};

export default CTAEightSection;
