import React, { useState, useEffect } from 'react';
import SetionTitle from "../SetionTitle/SetionTitle"
import Grid from '@material-ui/core/Grid';
import { Section, CardEight  } from "master-react-lib";
import img from '../../assets/imgs/readimg.png';
import { getEvents } from '../../functions';
import moment from 'moment';
const orgId = process.env.REACT_APP_ORGANIZATION_ID ; 


function UpcomingEvents() {

    useEffect( async () =>{

        let salahs = await getEvents(process.env.REACT_APP_ORGANIZATION_ID, 'deenweb');
        let tempData = [];

        if( salahs.data.body.data && Array.isArray( salahs.data.body.data )  ){

            salahs.data.body.data.map( element =>{

                if( tempData.length <2 ){
                    let object = {
                        title: element.event.title, 
                        img: element?.event.imageDetails?.imageUrl,
                        date:"JULY 9, 2022 | 7:30 AM",
                        // date: `${moment( element.event.eventStart?.startAt ).format('MMMM Do YYYY')} - ${moment( element.event?.endAt ).format('MMMM Do YYYY')}`,
                        link: '/events-details?q=' + element.event._id ,
                        // location: `${element.event?.location.address.line1} ${element.event?.location.address.line2} ${element.event?.location.address.city} ${element.event?.location.address.state}`,
                        location:<span>Prospect Park - Parade Ground No.8 <br/> Entrance - Caton Ave and Argyle Road</span>,
                        speakers: element.event?.speakers.map( speaker => {
                            return {
                                name: `${speaker.name.firstName} ${speaker.name.lastName}`,
                                image: speaker.imageMetaData.imageUrl
                            };
                        })
                    };
                    tempData.push( object );
                }
                
            });

            setEventCardOneData(tempData); 
        }        

    }, [] ); 

    const [eventCardOneData, setEventCardOneData] = useState( [

        

    ]) ;


    return (

        <Section
            background="#fff"
            //ContainerWidth=""
            Spacing="45px"

            Container={

                <div className='UpcomingEventCtn'>
                    <SetionTitle
                        TitleLight="UPCOMING"
                        TitleBold="BIC EVENTS"
                        buttonLebel="ALL EVENTS"
                        Link="/events"
                        ButtonStatus={true}
                    />
                    

                    <CardEight 
                        eventCardOneData={eventCardOneData}
                        col={6}
                        buttonLabel="View event Details"
                        groupStatus={true}
                        viewdetailStatus={true}
                        DateRangeStatus={true}
                        LocationStatus={true}
                        MoneyStatus={false}
                        FoodStatus={false}
                        JoinStatus={false}
                    />

                </div>

            }
        />
    );
}


export default UpcomingEvents;


