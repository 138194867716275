import React, { useState, useEffect } from "react";
import SetionTitle from "../SetionTitle/SetionTitle";
import Grid from "@material-ui/core/Grid";
import {
  Section,
  CardTwo,
  InputButton,
  DonationCardOne,
  VidoeListingCardOne,
  VideoPlayerModal,
} from "master-react-lib";
import img from "../../assets/imgs/readimg.png";
import playIcon from "../../assets/imgs/playIcon.png";
import { getVideos } from "../../functions";
const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function VideosSection() {
  const Youtube = (function () {
    var video, results;

    var getThumb = function (url, size) {
      if (url === null) {
        return "";
      }
      size = size === null ? "big" : size;
      // results = url.match('[\\?&]v=([^&#]*)');
      // video   = (results === null) ? url : results[1];

      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      video = match && match[7].length == 11 ? match[7] : false;

      if (size === "small") {
        return {
          link: "https://img.youtube.com/vi/" + video + "/2.jpg",
          id: video,
        };
      }
      return {
        link: "https://img.youtube.com/vi/" + video + "/0.jpg",
        id: video,
      };
    };

    return {
      thumb: getThumb,
    };
  })();

  //const [videoLink, setVieoLink] = useState('https://www.youtube.com/embed/FMBvXbIIm18');
  const [videoLink, setVieoLink] = useState(null);

  const [videoId, setVideoId] = useState("B_UQ7iKZjRg");

  const openVideoModal = (videoId) => (e) => {
    setVideoId(videoId);
    e.preventDefault();
    setOpen(true);
  };

  useEffect(async () => {
    let salahs = await getVideos(orgId, "deenweb");
    let tempData = [];
    let thumb;

    if (
      salahs &&
      salahs.data &&
      salahs.data.body &&
      salahs.data.body.data &&
      Array.isArray(salahs.data.body.data)
    ) {
      let filteredSalahs = salahs.data.body.data
        .filter((x) => x.featured === true)
        .sort((a, b) => a.video.order - b.video.order);

      setVieoLink(filteredSalahs[0].video.link);
      filteredSalahs.slice(1, 3).map((element) => {
        // if (tempData.length < 3) {
        thumb = Youtube.thumb(element.video.link, "small");
        let object = {
          img: thumb.link,
          title: element.video.title,
          date: element.publishFrom,
          views: "",
          path: "",
          link: element.video.link,
          onclick: openVideoModal(thumb.id),
        };
        tempData.push(object);
        // }
      });

      setVideoData(tempData);
    }
  }, []);

  const [videoListingOneData, setVideoData] = useState([]);

  const [isOpen, setOpen] = useState(false);

  return (
    <Section
      background="#F4F9FC"
      //ContainerWidth=""
      Spacing="45px"
      Container={
        <div>
          <SetionTitle
            TitleLight="BIC"
            TitleBold="VIDEOS"
            buttonLebel="ALL VIDEOS"
            Link="/videos"
            ButtonStatus={true}
          />

          <Grid container spacing={2}>
            <Grid item md={7} xs={12}>
              <iframe
                width="100%"
                height="369"
                src={videoLink}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </Grid>

            <Grid item md={5} xs={12}>
              <VidoeListingCardOne
                videoListingOneData={videoListingOneData}
                col="12"
                spacing="2"
                playicon={playIcon}
              />
            </Grid>
          </Grid>

          <VideoPlayerModal
            vidoeID={videoId}
            isOpen={isOpen}
            onClose={() => setOpen(false)}
          />
        </div>
      }
    />
  );
}

export default VideosSection;
