import React from 'react';
import { ServiceTabOne } from "master-react-lib";
import background from '../../assets/imgs/services_bg.jpg'
import SetionTitle from "../SetionTitle/SetionTitle"

function ServicesTab() {

    const tabItems = [
        {
            title: "DAILY AND JUMU'AH PRAYERS",
            TabPanelTitle: "DAILY AND JUMU'AH PRAYERS",
            TabPanelPara: <div>
                <p>The BIC Masjid is open for the 5 daily prayers including the Friday Jumu’ah (congregational) prayers. The Jumu’ah prayers are led by well-renowned Sheiks of diverse ethnicities and schools of thought aiming to maintain unity amidst diversity. The Masjid also consists of a separate floor dedicated for the sisters to engage in prayers and various other activities.</p>
                <p>The Prophet Muhammad (PBUH) said, "The best day during which the sun have risen is Friday. It is the Day Adam was created. It is the day when Adam entered paradise and also when he was taken out from it. It is also the day on which the Day of Judgment takes place." [Sahih Muslim]</p>
            </div>

        },

        {
            title: "RAMADHAN IFTAR AND TARAWIH",
            TabPanelTitle: "RAMADHAN IFTAR AND TARAWIH",
            TabPanelPara: <div>
                <p>“The month of Ramadhan [is that] in which was revealed the Qur'an, a guidance for the people and clear proofs of guidance and criterion. So whoever sights [the new moon of] the month, let him fast it; and whoever is ill or on a journey - then an equal number of other days. Allah intends for you ease and does not intend for you hardship and [wants] for you to complete the period and to glorify Allah for that [to] which He has guided you; and perhaps you will be grateful.”  [2: 185]</p>
                <p>Iftar services are provided for over hundreds of Muslims—brothers and sisters including children—daily throughout the blessed month of Ramadan. This provides a great opportunity to meet and bond with fellow Muslims within and outside the city, further strengthening the ties of brotherhood. Tarawih Prayers are also offered daily throughout the month, performed by dynamic, young and experienced Hafizes with mesmerizing recitations.</p>
            </div>

        },

        {
            title: "EID PRAYERS",
            TabPanelTitle: "EID PRAYERS",
            TabPanelPara: <div>
                <p>Abu Sa’eed al-Khudree (may Allah be pleased with him) said, “The Messenger of Allah (PBUH) used to go out to the Musallah (outdoor Prayer area) on the day of al-Fitr and al-Adha…’’  [Sahih Al- Bukhari & Muslim]</p>
                <p>The Eid-Ul-Fitr and the Eid-Ul-Adha Prayer services offered by the BIC take place in the open field at the Prospect Park, following the practice of our beloved Prophet Muhammad (PBUH). The Eid Prayers are attended by thousands of Muslims—brothers, sisters and children, within and outside the city limits. This helps to build and strengthen peace, harmony and togetherness amongst the Muslims of various cultures and professions. After the Eid prayer and Khutba, sweets and home-prepared meals are shared among the devotees, and gifts are given to the children.</p>
            </div>

        },

        {
            title: "MONTHLY/WEEKLY FAMILY NIGHT",
            TabPanelTitle: "MONTHLY/WEEKLY FAMILY NIGHT",
            TabPanelPara: <div>
                <p>A gathering of families of varying ethnicities and lifestyles cultivates harmony and acceptance amongst each other within the community. The Brooklyn Islamic Center, which strongly emphasizes on the significance of unity amongst fellow Muslims, initiated the ‘Family Night’ which takes place every month. The Family Night is the ideal way of associating with various other Muslim families over dinner, to take part in activities and engage in important communal discussions.</p>
                <p>The believing men and believing women are allies of one another. They enjoin what is right and forbid what is wrong and establish prayer and give charity and obey Allah and His Messenger. Allah will have mercy upon them, for Allah is Almighty and Wise.” [9: 71]</p>
            </div>

        },

        {
            title: "WEEKEND & SUMMER SCHOOL",
            TabPanelTitle: "WEEKEND & SUMMER SCHOOL",
            TabPanelPara: <div>
               <p>The Prophet (PBUH) said, "The best among you (Muslims) are those who learn the Qur'an and teach it." [Sahih Al- Bukhari]</p>
                <p>Brooklyn Islamic Center (BIC) offers the Weekend & Summer Islamic School conducted by experienced teachers aiming to instill the knowledge, practices, and most importantly the love of Islam in children.  The curriculum consists of lessons from the holy Quran, Islamic practices, ideal Islamic mannerisms, recitation of the Quran, etc. The Islamic School is for children, both boys and girls, from grades 4 - 12.</p>
                <p>The Summer School is conducted full time from July to August, and the Weekend School is conducted on weekends throughout the year.</p>
            </div>

        },

        {
            title: "HAJJ SEMINAR",
            TabPanelTitle: "HAJJ SEMINAR",
            TabPanelPara: <div>
                <p>Brooklyn Islamic Center (BIC) offers Seminars on Hajj for brothers and sisters engaging in the annual pilgrimage to the holy city of Makkah. The Hajj Seminar outlines the stories of the Prophets—Ibrahim & Ismail, including the significance, and merits of the fifth, fundamental pillar of Islam—Hajj. The Seminar also comprises of a step-by-step guide for Hajj, do’s and don’ts, and important factors that need to be considered prior to the journey.</p>
                <p>Brothers receive Ihram sets, while sisters receive Hijabs as gifts of participation, along with a dinner post seminar.</p>
            </div>

        },

    ];



    return (

        <div>
            <SetionTitle
                TitleLight="OUR"
                TitleBold="SERVICES"
                TitleLightEnd="FOR THE COMMUNITY"
                ButtonStatus={false}
            />
            <ServiceTabOne
                tabItems={tabItems}
                backgroundImg={background}
                TabBackground="#0053A5"
                DotColor="#0053A5"
                tabActivelabelcolor="#0053A5"
            />
        </div>
    );
}


export default ServicesTab;