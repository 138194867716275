import React from 'react';
import { AccordionOne } from "master-react-lib";
import FBicon from '../../assets/imgs/icon.png';


import locationIcon from '../../assets/imgs/event_location/locationIcon.svg';
import directionsIcon from '../../assets/imgs/event_location/directionsIcon.svg';
import facebookIcon from '../../assets/imgs/event_location/facebookIcon.svg';
import phoneIcon from '../../assets/imgs/event_location/phoneIcon.svg';
import youtubeIcon from '../../assets/imgs/event_location/youtubeIcon.svg';
import zoomIcon from '../../assets/imgs/event_location/zoomIcon.svg';

function EventLocation(props) {
    const [expanded, setExpanded] = React.useState('1');

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    const eventsLocationData = [
        {
            title: "Location/Venue",
            panel: "1",
            // body:  props.location,
            body:<div><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d3027.0643310085356!2d-73.97058713428885!3d40.650512299311245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1657022392501!5m2!1sen!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>,
            icon:locationIcon,
        },
        {
            title: props.location ,
            panel: "2",
            body: <a target="_blank" className='getDlink' href="https://www.google.com/maps/dir/722+Church+Ave,+Brooklyn,+NY+11218,+USA/Parade+Ground,+Coney+Island+Ave%2FChurch+Av,+Parkside+Ave,+Brooklyn,+NY+11218,+United+States/@40.6484569,-73.9728882,17z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x89c25b25433a84bd:0xdf377e2da5b0ea02!2m2!1d-73.9724197!2d40.6454914!1m5!1m1!1s0x89c25b3cf5e3bacb:0x6f2a08cff8057531!2m2!1d-73.9688658!2d40.6514144">Get Directions</a>,
            icon:directionsIcon,
        },

        // {
        //     title: "Zoom",
        //     panel: "5",
        //     body: "When the pilgrim is about 6 miles (10 km)",
        //     icon:zoomIcon,
        // },

        // {
        //     title: "Facebook",
        //     panel: "3",
        //     body: "When the pilgrim is about 6 miles (10 km)",
        //     icon:facebookIcon,
        // },
        // {
        //     title: "YouTube",
        //     panel: "4",
        //     body: "When the pilgrim is about 6 miles (10 km)",
        //     icon:phoneIcon,
        // },
        // {
        //     title: "Teleconference",
        //     panel: "5",
        //     body: "When the pilgrim is about 6 miles (10 km)",
        //     icon:youtubeIcon,
        // },
       
    ]


    return (
        <div className='locationAcc'>
            {eventsLocationData.map((item, i) => (
                <AccordionOne
                    title={item.title}
                    panel={item.panel}
                    expanded={expanded}
                    handleChange={handleChange}

                    ////// Styling ////////

                    titleColor="#1B2121"
                    titleFontSize="14px"
                    TitleFontWeight="600"
                    icon={item.icon}
                    iconStatus={true}

                    AccordionBody={
                        <div>
                            {item.body}
                        </div>
                    }

                />
            ))}

        </div>


    );
}


export default EventLocation;


