import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { SalatTable, Section, SliderThree } from "master-react-lib";
import bannerImg from '../../assets/imgs/BannerImg.jpg'
import { getSalahs, getSliders, getOrganization} from '../../functions';
import SalahTable from '../../assets/imgs/Salah-jun-22.jpg'

import moment from 'moment';
const orgId = process.env.REACT_APP_ORGANIZATION_ID ; 


function BannerSection(props) {
    const getTimeStamp = (time) => {
        let timestamp = moment(time).format("x");
        return timestamp
    }

    const [lastUpdated, setlastUpdated] = useState();

    useEffect( async () =>{
        
        const resSalahGet = await getSalahs(orgId, 'deenweb')

        const SalahData = resSalahGet?.data?.body?.data

        const ActiveSalah = SalahData.filter(text => text.status === 'true')


        const resOrg = await getOrganization(orgId);
        const orgIntegate = resOrg.data?.body?.data?.enabledIntegrations;
        
        // console.log(orgIntegate)

        const salah = orgIntegate?.deenweb?.salahs?.selectedSalahs
        
        const d1 = new Date();
        const d = Number(d1)
        const resData = salah.filter((item => item.status === "Public"));  //&& Number(new Date(item.publishFrom)) < d && Number(new Date(item.publishTo)) > d)
        console.log("salah with App ID: ", resData)
            
        const Ids = resData.length ? resData.map(t => t.salah) : [];
        // console.log("Active Salah")
        // console.log(Ids)
        
        let updatedSalahObj = []

        const SalahToDisplay = ActiveSalah.filter(text => Ids.includes(text._id))
        
        console.log("SalahToDisplay: ", SalahToDisplay)

        let sortedSalah = SalahToDisplay?.filter((el) => el.order).sort((a, b) => {
                return a.order - b.order
        })
        // let sortedSalah = SalahToDispla; 
        console.log( sortedSalah )

        const lu = sortedSalah?.length ? sortedSalah.sort((a, b) => {

            return getTimeStamp(a.updatedAt) - getTimeStamp(b.updatedAt)
        }) : []


        
        let tempData = [];

        if( lu  && Array.isArray( lu  )  ){

            setlastUpdated(lu?.[0]?.updatedAt); 

            console.log("Ly", lu );

            lu.map( element =>{

                let name = element?.salahName?.fullName?.split("(")
                            
                name = name?.length ? name[0] : salah?.salahName.fullName;
                let salahTime = element?.salahStart?.iqamahTime ? moment(element?.salahStart?.iqamahTime).utcOffset(0).format('LT') : ""
                    let object = {
                        col1: name, 
                        col2: salahTime
                    };
                    tempData.push( object );
                

                
            });

            settableBodyData(tempData); 
        }  
        
        let banners = await getSliders( orgId );

        tempData = [];

        if( banners.data.body.data && Array.isArray( banners.data.body.data )  ){

            banners.data.body.data.filter( x => x.website.status === "Public" ).map( element =>{                
                    
                let object = {
                    img: element?.imageMetaData?.imageUrl, 
                    title: element?.title,
                    readmore: element.button?.buttonLabel,
                    link: element.button?.buttonLink,
                };
                tempData.push( object );
                                
            });

             setBannerData(tempData); 
        } 

    }, [] ); 



    

    const [tableBodyData, settableBodyData] = useState([]);



     const [bannerData, setBannerData] = useState( []);

// link:"https://bicny.org/media/Salah-jun-22.jpg",

    return (

        <Section
            background="#F4F9FC"
            //ContainerWidth=""
            Spacing="0px"

            Container={
                <Grid container spacing={3}>

                    <Grid item xs={12} md={8}>
                        <SliderThree
                            bannerData={bannerData}
                            background="#003467"
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <SalatTable
                            TableTitle="SALAT/IQAMAH TIME"
                            tabletitleStatus={true}
                            title={"HAJJ 2021 PACKAGES"}
                            TitleColor="#FFFFFF"
                            TitleBackground="#0053A5"
                            tableBodyData={tableBodyData}
                            footerText={lastUpdated && <p>Last Updated {lastUpdated ? moment(lastUpdated).format('LL') : ""} / {lastUpdated ? moment(lastUpdated).utcOffset(-5).format("h:mm A") + " EST" : ""}</p>}
                            footerTextStatus={true}

                            TableBackground="#F4F9FC"
                            tbaleBorder="#0053A5 1px solid"
                            TalePadding="0 20px"

                            odd="#F4F9FC"
                            even="#F4F9FC"
                            colBorder="#E4E8EC 1px solid"
                            col1Color="#0053A5"
                            col1fontWeight="600"
                            col2Color="#0B132B"
                            col2fontWeight="400"
                            ColfontSizet="16px"
                        />
                    </Grid>

                </Grid>
            }
        />


    );
}


export default BannerSection;