import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Section, BlogOne } from "master-react-lib";
import CarosalImg from '../CarosalImg/CarosalImg'


import Slider_1 from '../../assets/imgs/DEENCONNECT-BICNY-722-ChurchAve-NewProject.webp'
import Slider_2 from '../../assets/imgs/DEENCONNECT-BICNY-722-ChurchAve-NewProject-2.webp'
import Slider_3 from '../../assets/imgs/DEENCONNECT-BICNY-722-ChurchAve-NewProject-3.webp'

function BuildProject() {

    const blogOneData = [
        {
            titleBold: "New Largest Islamic Center with 68,000 Sq. Ft in the heart of NYC and need your generous support.",
            description: <p>Building the largest and state-of-the-art Islamic Center in the heart of NYC is not only the dream but a need in Brooklyn and for the next generations. The proposed 8-stories building would cost $22 Million to build with a footprint of 68,000 Sq Ft and will be the epic center and a landmark for NYC.</p>,
        }

    ];


    const images = [
        {
            imgPath: Slider_1
        },
        {
            imgPath: Slider_2
        },
        {
            imgPath: Slider_3
        },

    ];


    return (

        <Section
            background="#0053A5"
            //ContainerWidth=""
            Spacing="45px"

            Container={


                <div >

                    <Grid container spacing={3}>

                        <Grid item md={7} xs={12}>
                            <BlogOne
                                col="12"
                                Spacing="40px"
                                blogOneData={blogOneData}
                                color="#fff"
                                titleLightFontSise="34px"
                                titleBoldFontSise="34px"
                                descriptionFontSise="13px"

                                //// Button Style ////
                                buttonLebel="SEE MORE DETAILS"
                                link="https://build.bicny.org/"
                                target="blank"
                                arrow={false}
                                arrowColor="#fff"
                                buttoncolor="#FF6200"
                                width="auto"
                                height="47px"
                                border="#ccc 0px solid"
                                borderRadius="4px"
                                colorlabel="#fff"
                                fontWeight="600"
                                textAlign="center"


                            />

                        </Grid>

                        <Grid item md={5} xs={12}>

                            <CarosalImg 
                                images={images}
                            />

                        </Grid>

                    </Grid>






                </div>

            }
        />
    );
}


export default BuildProject;


