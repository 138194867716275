import React, { useState, useEffect } from "react";
import {
  VidoeListingCardTwo,
  ButtonOne,
  VideoPlayerModal,
} from "master-react-lib";
import ArrowForwardIosRoundedIcon from "@material-ui/icons/ArrowForwardIosRounded";
import img from "../../assets/imgs/img.png";
import playIcon from "../../assets/imgs/playIcon.png";
import { getVideos } from "../../functions";
const orgId = process.env.REACT_APP_ORGANIZATION_ID;

function VideoListingSidebar() {
  const Youtube = (function () {
    var video, results;

    var getThumb = function (url, size) {
      if (url === null) {
        return "";
      }
      size = size === null ? "big" : size;
      // results = url.match('[\\?&]v=([^&#]*)');
      // video   = (results === null) ? url : results[1];

      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      video = match && match[7].length == 11 ? match[7] : false;

      if (size === "small") {
        return {
          link: "https://img.youtube.com/vi/" + video + "/2.jpg",
          videoId: video,
        };
      }
      return {
        link: "https://img.youtube.com/vi/" + video + "/0.jpg",
        videoId: video,
      };
    };

    return {
      thumb: getThumb,
    };
  })();

  useEffect(async () => {
    let salahs = await getVideos(orgId, "deenweb");
    let tempData = [];
    let thumb;

    if (
      salahs &&
      salahs.data &&
      salahs.data.body &&
      salahs.data.body.data &&
      Array.isArray(salahs.data.body.data)
    ) {
      salahs.data.body.data.map((element) => {
        if (tempData.length < 4) {
          thumb = Youtube.thumb(element.video.link, "small");
          let object = {
            img: thumb.link,
            title: element.video.title,
            date: element.publishFrom,
            views: "",
            path: "",
            link: element.video.link,
            subtitle: "BIC ADMIN",
            onclick: videoClick(thumb.videoId),
          };
          tempData.push(object);
        }
      });

      setVideoListingData(tempData);
    }
  }, []);

  const click = () => {};

  const videoClick = (id) => (e) => {
    e.preventDefault();
    setvidoeID(id);
    setOpen(true);
  };

  const [videoListingData, setVideoListingData] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [vidoeID, setvidoeID] = useState("");

  return (
    <div>
      <div className="sideBarSectoinTitle">KHUTBA & VIDEOS</div>
      <VidoeListingCardTwo
        videoListingTwoData={videoListingData}
        col="12"
        spacing="1"
        Thumnailwidth=""
        playicon={playIcon}
        playiconStatus={true}
        borderLine={true}
      />
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <ButtonOne
          label="MORE VIDEOS"
          onClick={click}
          link="/videos"
          //target="blank"
          path="/"
          arrowIcon={<ArrowForwardIosRoundedIcon />}
          arrowStatus={true}
          arrowColor="#0B132B"
          buttoncolor="#fff"
          width=""
          height="50px"
          border="#0053A5 1px solid"
          borderRadius="4px"
          color="#0B132B"
          fontWeight="600"
          textAlign="center"
        />
      </div>

      <VideoPlayerModal
        vidoeID={vidoeID}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
      />
    </div>
  );
}

export default VideoListingSidebar;
